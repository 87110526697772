import React from 'react';

import Subheader from '../components/Subheader/Subheader';
import Contacts from '../components/Contacts/Contacts';
import MainOffice from '../components/MainOffice/MainOffice';
import ContactSteps from '../components/ContactsSteps/ContactSteps';
import Seo from '../components/SEO/SEO';

import { SEO_ITEMS } from '../constants/SEOItems';

const crumbs = [
  {
    title: 'Головна',
    link: '/',
  },
  {
    title: 'Контакти',
    link: '/contacts',
  },
];

const ContactsPage: React.FC = (): JSX.Element => {
  return (
    <div className="contacts-page page">
      <Seo
        breadcrumbs={crumbs}
        description={SEO_ITEMS.contactsPage.description}
        lang="ua"
        path="/contacts"
        title={SEO_ITEMS.contactsPage.title}
      />
      <Subheader crumbs={crumbs} />
      <Contacts />
    </div>
  );
};

export default ContactsPage;
